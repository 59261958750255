import React from "react";
import logo from "./crowded_pool_logo.svg";
import "./App.css";
import googleImage from "./google-play-badge.png";
import appstore from "./appstore.png";
import preview from "./preview.png";

const Footer = () => (
  <div className="footer">
    <p> ©2022 Apps Can Fly, LLC</p>
    <a href="mailto:support@appscanfly.com">
      <i
        className="fa fa-envelope"
        style={{ fontSize: "24px", color: "white", marginRight: "2px" }}
      ></i>
      <p> Contact Us</p>
    </a>
  </div>
);

const Screenshots = () => {
  return (
    <div className="left">
      <img src={preview} alt="preview-inmage" />
    </div>
  );
};
const Info = () => {
  return (
    <div className="right">
      <div className="logo">
        <img src={logo} alt="logo" />
        <span className="logo1">Crowded</span>
        <span className="logo2">Pool</span>
      </div>
      <div className="tag">
        Plan your Pool time to avoid the Crowds at your Community Pool
      </div>
      <ul className="fetaures fa-ul">
        <li>
          <i className="fa-li fa fa-check-square" />
          See crowd levels at your Community Pool
        </li>
        <li>
          <i className="fa-li fa fa-check-square" />
          Share Pool crowd levels with your Neighbors
        </li>
        <li>
          <i className="fa-li fa fa-check-square" />
          Schedule Pool time in your Community Pool
        </li>
      </ul>

      <div className="links">
        <a
          href="https://play.google.com/store/apps/details?id=com.appscanfly.crowdedpool"
          target="_blank"
          rel="noreferrer"
        >
          <img src={googleImage} alt="google-play" />
        </a>
        <a
          href="https://apps.apple.com/us/app/crowdedpool/id1628204212"
          target="_blank"
          rel="noreferrer"
        >
          <img src={appstore} alt="appstore" />
        </a>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <div className="App">
      <div className="container">
        <Screenshots />
        <Info />
      </div>
      <Footer />
    </div>
  );
};

export default App;
