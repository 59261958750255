import React, { useEffect, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  GeoPoint,
  collection,
  query,
  getFirestore,
  where,
  getDocs,
  addDoc,
  doc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  User,
  signOut,
} from "firebase/auth";
import { states } from "./states";
import Select from "react-select";

export interface Pool {
  id: string;
  name: string;
  capacity?: number;
  community: string;
  city: string;
  state: string;
  latlon: GeoPoint;
}

export interface Community {
  id: string;
  name: string;
  name_lc: string;
  city: string;
  state: string;
  zip: string;
  pools: Pool[];
}

const firebaseConfig = {
  apiKey: "AIzaSyDT-bOpOasn0hVsnxEG--uJW6_RTPmyRYY",
  authDomain: "crowdedpool.firebaseapp.com",
  projectId: "crowdedpool",
  storageBucket: "crowdedpool.appspot.com",
  messagingSenderId: "725625199798",
  appId: "1:725625199798:web:c0db0697d6544809985a17",
  measurementId: "G-P7JT7GJEC0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const Admin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [state, setState] = useState("");
  const [cities, setCities] = useState<string[]>([]);
  const [city, setCity] = useState("");
  const [zip, setZip] = useState<string>("");
  const [community, setCommunity] = useState<string>("");
  const [latlon, setLatlon] = useState<string>("");
  const [name, setName] = useState<string>("Main Pool");
  const [capacity, setCapacity] = useState<string>("100");
  const [communities, setCommunities] = useState<Community[]>([]);
  useEffect(() => {
    if (state) {
      setCities(states[state].sort());
      setCity("");
    }
  }, [state]);

  const login = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((cred) => {
        setUser(cred.user);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (u) => {
      setUser(u);
    });
    const q = query(collection(db, "pools"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const xcommunites: Community[] = querySnapshot.docs.reduce(
        (prev, curr) => {
          const id = curr.id;
          const {
            name,
            capacity,
            community,
            community_lc,
            city,
            state,
            zip,
            latlon,
          } = curr.data();
          const dCommunity: Community = {
            name: community,
            name_lc: community_lc,
            pools: [
              {
                id,
                name,
                community,
                latlon: latlon as GeoPoint,
                city,
                state,
              },
            ],
            city,
            state,
            zip,
            id: `${community}${city}${state}${zip}`,
          };
          const idx = prev.findIndex(
            (x) =>
              x.name_lc === dCommunity.name_lc &&
              x.city === dCommunity.city &&
              x.state === dCommunity.state &&
              x.zip === dCommunity.zip
          );
          if (idx !== -1) {
            prev[idx].pools.push({
              name,
              id,
              capacity,
              community,
              latlon: latlon as GeoPoint,
              city,
              state,
            });
          } else {
            prev.push(dCommunity);
          }

          return prev;
        },
        [] as Community[]
      );
      setCommunities(xcommunites);
    });

    return () => unsubscribe();
  }, []);

  const save = async () => {
    const lat = latlon.split(",")[0].trim();
    const lon = latlon.split(",")[1].trim();
    const data = {
      capacity: capacity ? Number(capacity.trim()) : 100,
      city: city.trim(),
      state: state.trim(),
      zip: zip.trim(),
      community: community.trim(),
      community_lc: community.toLowerCase(),
      dateCreated: new Date(),
      name: name.trim(),
      country: "USA",
      latlon: new GeoPoint(Number(lat), Number(lon)),
      id: doc(collection(db, "pools")).id,
      trafficUpdateRestrictedToAdmin: false,
      poolAdmin: [],
    };

    const poolRef = collection(db, "pools");

    const q = query(
      poolRef,
      where("name", "==", data.name),
      where("community", "==", data.community),
      where("city", "==", data.city),
      where("state", "==", data.state)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      alert("Duplicate");
      return;
    }
    try {
      await setDoc(doc(poolRef, data.id), data);
    } catch (e) {
      console.log(e);
      alert("fail");
    }
  };

  function isNumeric(value: string) {
    return /^-?\d+$/.test(value);
  }

  return (
    <div>
      {user && <h2 style={{ margin: "1rem" }}>{user.email}</h2>}
      {user && <button onClick={() => signOut(auth)}>Sign Out</button>}
      {!user && (
        <div
          style={{ display: "flex", flexDirection: "column", width: "300px" }}
        >
          <input
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ margin: "0.5rem" }}
          />
          <input
            placeholder="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ margin: "0.5rem" }}
          />
          <button onClick={login} style={{ margin: "0.5rem" }}>
            Login
          </button>
        </div>
      )}
      {user && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              margin: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h2>Pools</h2>
            <ul>
              {communities.map((community) => (
                <li
                  key={community.id}
                  style={{ fontWeight: "bold", margin: "0.5rem" }}
                >
                  {community.name}
                  <span style={{ color: "gray", marginLeft: "5px" }}>
                    | {community.city}, {community.state}
                  </span>
                  <ul style={{ fontWeight: "normal" }}>
                    {community.pools.map((pool) => (
                      <li key={pool.id}>
                        {pool.name} ({pool.capacity ?? "N/A"})
                        <span
                          style={{
                            color: "gray",
                            marginLeft: "5px",
                            fontSize: "12px",
                          }}
                        >
                          | {pool.latlon.latitude}, {pool.latlon.longitude}
                        </span>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <div
            style={{
              margin: "1rem",
              display: "flex",
              flexDirection: "column",
              maxWidth: "300px",
            }}
          >
            <h2>Add New Community</h2>
            <div style={{ fontWeight: "bold", marginTop: "10px" }}>
              Community Name
            </div>
            <input
              placeholder="Community Name"
              value={community}
              onChange={(e) => setCommunity(e.target.value)}
            />
            <div style={{ fontWeight: "bold", marginTop: "10px" }}>
              Pool Name
            </div>
            <input
              placeholder="Pool Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div style={{ fontWeight: "bold", marginTop: "10px" }}>
              Capacity
            </div>
            <input
              placeholder="Pool Capacity"
              pattern="numeric"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
            />
            <div style={{ fontWeight: "bold", marginTop: "10px" }}>State</div>
            <Select
              value={{ label: state, value: state }}
              onChange={(e) => setState(e?.value ?? "")}
              options={Object.keys(states)
                .sort()
                .map((state) => ({ label: state, value: state }))}
            />
            <div style={{ fontWeight: "bold", marginTop: "10px" }}>City</div>
            <Select
              value={{ label: city, value: city }}
              onChange={(e) => setCity(e?.value ?? "")}
              options={cities
                .sort()
                .map((state) => ({ label: state, value: state }))}
            />

            <div style={{ fontWeight: "bold", marginTop: "10px" }}>
              Zip Code
            </div>
            <input
              placeholder="Zip"
              value={zip}
              onChange={(e) => setZip(e.target.value)}
            />
            <div style={{ fontWeight: "bold", marginTop: "10px" }}>Lat Lon</div>
            <input
              placeholder="Lat, Lon"
              value={latlon}
              onChange={(e) => setLatlon(e.target.value)}
            />
            <button
              style={{ marginTop: "10px" }}
              onClick={save}
              disabled={
                !community ||
                !name ||
                !city ||
                !state ||
                !zip ||
                !latlon ||
                !isNumeric(capacity)
              }
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Admin;
